body {
  margin: 0;
  font-family: Roboto, sans-serif;
}

/* fixes dropdownlist in dialog issue */
.k-animation-container {
  z-index: 10003;
}
.k-dialog-wrapper {
  z-index: 10003;
}
